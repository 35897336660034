import React, { Fragment, useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from "react-router-dom";
import { format } from 'date-fns';
import { tr } from 'date-fns/locale';
import "react-calendar/dist/Calendar.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Button, Dropdown, Modal, Row, Col, Card, Tab, Nav } from "react-bootstrap";
import Spinner from '../../components/Spinner/Spinner';
import { getMeeting, getSubMeetings, getMeetingAgendas, getMeetingAnnouncements, getMeetingUsers, joinMeeting, getMeetingNotes, createMeetingNote, reset, updateMeetingRecord } from '../../features/meeting/meetingSlice';
import MeetingGallery from '../../jsx/components/Ventic/Meeting/MeetingGallery';
import { storage } from "../../config/firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { toast } from 'react-hot-toast';



function MeetingDetail() {
    let { meetingId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [activeToggle, setActiveToggle] = useState("about");
    const { user } = useSelector((state) => state.auth);
    const isAuthenticated = useSelector((state) => state.auth.user !== null);
    const myUserId = useSelector((state) => state.auth.user.uid);


    const { meeting = {}, 
            meetings = [], 
            meetingAnnouncements = [], 
            meetingUsers = [], 
            meetingNotes = [], 
            meetingAgendas = [], 
            isLoading, 
            isError, 
            message } = useSelector((state) => state.meeting);

    const [seconds, setSeconds] = useState(0);

    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const [meetingRecord, setMeetingRecord] = useState(null);
    const [isDragging, setIsDragging] = useState(false);

    useEffect(() => {
        if (!isAuthenticated) { navigate('/login') }
        if (isError) { console.log(message); }
        dispatch(getMeeting(meetingId))
        dispatch(getMeetingUsers(meetingId))
        //dispatch(getMeetingNotes(meetingId))
        //dispatch(getMeetingAgendas(meetingId))

        /*
        const interval = setInterval(() => {
            setSeconds(seconds => seconds + 1);
        }, 30000);
        */

        // return () => { if (isAuthenticated) { dispatch(reset()) } }
        //}, [isAuthenticated, navigate, isError, message, dispatch])
    }, [isAuthenticated, navigate])

    // Toplantı verisi yüklendiğinde, ses kaydı bilgisini kontrol et
    useEffect(() => {
        if (meeting && (meeting.recordingUrl || meeting.meetingrecordurl)) {
            setMeetingRecord({
                url: meeting.recordingUrl || meeting.meetingrecordurl,
                name: meeting.recordingName || "Toplantı Ses Kaydı",
                updateDate: meeting.recordingUpdateDate || null
            });
        }
    }, [meeting]);

    const [noteTitle, setNoteTitle] = useState('')
    const [noteContext, setNoteContext] = useState('')




    const meetingNoteData = {
        title: noteTitle,
        context: noteContext,
        files: [],
        meetingId: meetingId,
        state: true,
        status: true,
        type: "1",
        noteId: "",
    };

    const onMeetingJoin = e => {
        //e.prmeetingDefault();
        dispatch(joinMeeting(meetingId))
        setSeconds(seconds => seconds + 1);
    }

    const onSubmitNote = e => {
        e.prmeetingDefault();

        if (noteTitle !== '' || noteContext !== '') {
            dispatch(createMeetingNote(meetingNoteData));
            setNoteTitle('');
            setNoteContext('');
            console.log(meetingNoteData);
        }

    }

    //if (isLoading) { return (<>Yükleniyor</>) }

    // Safely handle main meeting dates - Güvenli bir şekilde toplantı tarihlerini işleme
    let startDateGet = new Date("2025-01-01");
    let endDateGet = new Date("2035-01-01");

    try {
        if (meeting && meeting.startDate) {
            if (meeting.startDate.toDate) {
                // Handle Firestore Timestamp
                startDateGet = meeting.startDate.toDate();
                endDateGet = meeting.endDate.toDate();
            } else if (meeting.startDate.seconds) {
                // Handle seconds timestamp
                startDateGet = new Date(meeting.startDate.seconds * 1000);
                endDateGet = new Date(meeting.endDate.seconds * 1000);
            } else if (typeof meeting.startDate === 'string') {
                // Handle string date
                startDateGet = new Date(meeting.startDate);
                endDateGet = new Date(meeting.endDate);
            }
        }
    } catch (error) {
        console.error('Invalid meeting date format:', error);
        startDateGet = new Date();
        endDateGet = new Date();
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Maksimum 100MB olarak kontrol edelim
            const maxSize = 100 * 1024 * 1024; // 100MB in bytes
            if (file.size > maxSize) {
                toast.error('Dosya boyutu çok büyük. Lütfen 100MB\'dan küçük bir dosya seçin.');
                e.target.value = ''; // Input'u temizle
                return;
            }
            
            // Desteklenen ses formatlarını kontrol et
            const supportedTypes = ['audio/mpeg', 'audio/wav', 'audio/mp4', 'audio/aac', 'audio/ogg'];
            const fileExtension = file.name.split('.').pop().toLowerCase();
            const validExtensions = ['mp3', 'wav', 'm4a', 'aac', 'ogg', 'mp4'];
            
            if (!supportedTypes.includes(file.type) && !validExtensions.includes(fileExtension)) {
                toast.error('Desteklenmeyen dosya formatı. Lütfen MP3, WAV, M4A, AAC veya OGG formatında bir dosya seçin.');
                e.target.value = ''; // Input'u temizle
                return;
            }
            
            setSelectedFile(file);
            toast.success('Dosya başarıyla seçildi. Yüklemek için "Kayıt Yükle" butonuna tıklayın.');
        }
    };

    const handleRecordSubmit = async (e) => {
        e.preventDefault();
        if (!selectedFile) {
            toast.error('Lütfen bir ses dosyası seçin.');
            return;
        }

        try {
            setIsUploading(true);
            toast.loading('Ses kaydı yükleniyor...', { id: 'audioUpload' });
            
            // Kullanıcıya sayfadan ayrılmamasını hatırlatalım
            window.onbeforeunload = () => {
                return "Dosya yükleme işlemi devam ediyor. Sayfadan ayrılırsanız yükleme iptal edilecektir.";
            };
            
            const meetingData = {
                recordFile: selectedFile
            };
            
            // Bilgi mesajı ekleyelim
            //toast.info('Dosyanız güvenlik için benzersiz bir ad ile kaydedilecektir.', { duration: 3000 });
            
            // Dispatch the action to update meeting record
            const result = await dispatch(updateMeetingRecord({ 
                meetingData, 
                id: meeting.id,
                onProgress: (progress) => {
                    setUploadProgress(progress);
                }
            })).unwrap();
            
            // Update the meeting record state with the new URL and file name
            setMeetingRecord({
                url: result.recordingUrl,
                name: result.recordingName,
                updateDate: result.recordingUpdateDate
            });
            
            // Reset form state
            setIsUploading(false);
            setSelectedFile(null);
            setUploadProgress(0);
            
            // Sayfa ayrılma uyarısını kaldıralım
            window.onbeforeunload = null;
            
            toast.success('Ses kaydı başarıyla yüklendi!', { id: 'audioUpload' });
        } catch (error) {
            console.error("Upload error:", error);
            setIsUploading(false);
            setUploadProgress(0);
            
            // Sayfa ayrılma uyarısını kaldıralım
            window.onbeforeunload = null;
            
            // Daha açıklayıcı hata mesajları ekleyelim
            let errorMessage = 'Bilinmeyen bir hata oluştu';
            
            if (error.message) {
                if (error.message.includes('storage/unauthorized')) {
                    errorMessage = 'Ses kaydı yükleme izniniz bulunmuyor. Lütfen yöneticinizle iletişime geçin.';
                } else if (error.message.includes('storage/quota-exceeded')) {
                    errorMessage = 'Depolama limitinize ulaştınız. Lütfen yöneticinizle iletişime geçin.';
                } else if (error.message.includes('storage/canceled')) {
                    errorMessage = 'Yükleme iptal edildi.';
                } else if (error.message.includes('storage/unknown')) {
                    errorMessage = 'Sunucu bağlantısında bir sorun oluştu. Lütfen internet bağlantınızı kontrol edin.';
                } else {
                    errorMessage = `Yükleme sırasında bir hata oluştu: ${error.message}`;
                }
            }
            
            toast.error(errorMessage, { id: 'audioUpload' });
        }
    };

    const formatStartEndDate = (startDate, endDate) => {
        if (!startDate || !endDate) return '';
        try {
            // Handle both Firestore Timestamp and regular Date objects
            const startDateObj = startDate.toDate ? startDate.toDate() : new Date(startDate);
            const endDateObj = endDate.toDate ? endDate.toDate() : new Date(endDate);
            
            // Tarih objelerinin geçerli olup olmadığını kontrol et
            if (isNaN(startDateObj.getTime()) || isNaN(endDateObj.getTime())) {
                console.error("Invalid date objects:", startDateObj, endDateObj);
                return '';
            }
            
            // format fonksiyonunu doğru parametrelerle çağır
            return format(startDateObj, "dd MMM yyyy HH:mm") + " - " + format(endDateObj, "HH:mm");
        } catch (error) {
            console.error("Date formatting error:", error);
            return '';
        }
    };

    // Drag and drop handlers
    const handleDragOver = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setIsDragging(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragging(false);
        
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            const file = e.dataTransfer.files[0];
            // Reuse the same validation logic from handleFileChange
            const maxSize = 100 * 1024 * 1024;
            if (file.size > maxSize) {
                toast.error('Dosya boyutu çok büyük. Lütfen 100MB\'dan küçük bir dosya seçin.');
                return;
            }
            
            const supportedTypes = ['audio/mpeg', 'audio/wav', 'audio/mp4', 'audio/aac', 'audio/ogg'];
            const fileExtension = file.name.split('.').pop().toLowerCase();
            const validExtensions = ['mp3', 'wav', 'm4a', 'aac', 'ogg'];
            
            if (!supportedTypes.includes(file.type) && !validExtensions.includes(fileExtension)) {
                toast.error('Desteklenmeyen dosya formatı. Lütfen MP3, WAV, M4A, AAC veya OGG formatında bir dosya seçin.');
                return;
            }
            
            setSelectedFile(file);
            toast.success('Dosya başarıyla seçildi. Yüklemek için "Kayıt Yükle" butonuna tıklayın.');
        }
    };

    return (
        <Fragment>
            <style>
                {`
                .bg-success-light {
                    background-color: rgba(40, 167, 69, 0.1) !important;
                }
                
                .upload-container {
                    transition: all 0.3s ease;
                    border: 2px dashed #ccc;
                    border-radius: 8px;
                    padding: 1.5rem;
                }
                
                .upload-container:hover {
                    border-color: #6c757d;
                }
                
                .upload-container.dragging {
                    border-color: #20c997;
                    background-color: rgba(32, 201, 151, 0.1);
                    transform: scale(1.01);
                }
                
                .progress-bar {
                    transition: width 0.5s ease;
                }
                
                .audio-player {
                    width: 100%;
                    margin-top: 10px;
                }
                
                .selected-file {
                    transition: all 0.2s ease;
                }
                
                .selected-file:hover {
                    transform: translateY(-2px);
                    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
                }
                `}
            </style>
            <div className="row">
                <div className="col-lg-12">
                    <div className="profile card card-body px-3 pt-3 pb-0">
                        <div className="profile-head">
                            
                            <div className="profile-info">
                               
                                <div className="profile-details">
                                    <div className="profile-name px-3 pt-2">
                                        <h4 className="text-info mb-0">{meeting.title}</h4>
                                        <p>
                                            Katılımcı Sayısı:  {meeting.users?.length}
                                            <br />
                                            Tarih Saat: {formatStartEndDate(meeting.startDate, meeting.endDate)}
                                        </p>
                                    </div>

                                    <Dropdown className="dropdown ms-auto" onClick={() => setActiveToggle("about")}>
                                        <div className="ticket-icon44  ms-0 d-none d-sm-inline-block" onClick={() => setActiveToggle("about")}>
                                            <i className="fa fa-info fs-16" aria-hidden="true"></i>
                                        </div>
                                    </Dropdown>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




            <div className="row">

                <div className="col-xl-4">
                    <div className="row">

                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    {!meeting.users?.includes(myUserId) ? (
                                        <>
                                            <a onClick={() => onMeetingJoin()} >
                                                <div className="media">
                                                    <span className="me-3">
                                                        <i class="fa fa-check"></i>
                                                    </span>
                                                    <div className="media-body">
                                                        <h4 className="">1- Toplantıya Katıl</h4>
                                                    </div>
                                                </div>
                                            </a>
                                        </>
                                    ) : (
                                        <>
                                            {new Date(new Date(meeting.startDate).getTime() - 5 * 60000) <= new Date() && 
                                             new Date(new Date(meeting.endDate).getTime() + 5 * 60000) >= new Date() ? (
                                                <>
                                                    {meeting && Array.isArray(meeting.adminUsers) && meeting.adminUsers.includes(myUserId) ? (
                                                        <a href={meeting.urlHost || "#"}>
                                                            <div className="media">
                                                                <span className="me-3">
                                                                    <i className="fa fa-play"></i>
                                                                </span>
                                                                <div className="media-body">
                                                                    <h4 className="">Toplantı Devam Ediyor</h4>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    ) : (
                                                        <a href={meeting && meeting.urlAttendee ? meeting.urlAttendee : "#"}>
                                                            <div className="media">
                                                                <span className="me-3">
                                                                    <i className="fa fa-play"></i>
                                                                </span>
                                                                <div className="media-body">
                                                                    <h4 className="">Toplantı Devam Ediyor</h4>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    {new Date(meeting.startDate) >= new Date() && new Date(meeting.endDate) >= new Date() ? (
                                                        <>
                                                            <div className="media">
                                                                <span className="me-3">
                                                                    <i className="fa fa-check"></i>
                                                                </span>
                                                                <div className="media-body">
                                                                    <h5 className="">Toplantı Başlamadı</h5>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className="media">
                                                                <span className="me-3">
                                                                    <i className="fa fa-check"></i>
                                                                </span>
                                                                <div className="media-body">
                                                                    <h5 className="">Toplantı Sona Erdi</h5>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        
                                        </>
                                    )}
                                </div>
                            </div>

                        </div>

                        
                        {meetingRecord && meetingRecord.url && (
                            
                            <div className="col-lg-12" onClick={() => setActiveToggle("record")}>
                                <div className="meeting-record-summary p-2 mt-0 mb-4 bg-light rounded border-start border-info border-4 d-flex align-items-center">
                                    <i className="fa fa-headphones text-info fa-2x me-3"></i>
                                    <div>
                                        <h6 className="mb-0 me-2">Bu toplantı için ses kaydı mevcut</h6>
                                    </div>
                                    <div className="ms-auto">
                                        <button 
                                            type="button" 
                                            className="btn btn-sm btn-info"
                                            onClick={() => setActiveToggle("record")}
                                        >
                                            <i className="fa fa-play-circle me-1"></i>Dinle
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}


                        <div className="col-lg-12">
                            <div className="card">

                                <div className="card-body">

                                    <div className="media align-items-center border-bottom p-2" onClick={() => setActiveToggle("about")}>
                                        <div className="ticket-icon44  ms-0 me-3 d-none d-sm-inline-block">
                                            <i className="fa fa-info fs-16" aria-hidden="true"></i>
                                        </div>
                                        <div className="media-body">
                                            <h5 className="mt-0 mb-0" onClick={() => setActiveToggle("about")}>Toplantı Hakkında</h5>
                                        </div>
                                    </div>

                                    <div className="media align-items-center border-bottom p-2" onClick={() => setActiveToggle("record")}>
                                        <div className="ticket-icon44  ms-0 me-3 d-none d-sm-inline-block">
                                            <i className="fa fa-microphone fs-16" aria-hidden="true"></i>
                                        </div>
                                        <div className="media-body">
                                            <h5 className="mt-0 mb-0" onClick={() => setActiveToggle("record")}>Toplantı Ses Kaydı</h5>
                                        </div>
                                    </div>

                                    {meeting.isTranscribed ? (


                                        <div className="media align-items-center border-bottom p-2" onClick={() => setActiveToggle("transcript")}>
                                            <div className="ticket-icon44  ms-0 me-3 d-none d-sm-inline-block">
                                                <i className="fa fa-pencil fs-16" aria-hidden="true"></i>
                                            </div>
                                            <div className="media-body">
                                                <h5 className="mt-0 mb-0" onClick={() => setActiveToggle("transcript")}>Toplantı Dökümü</h5>
                                            </div>
                                        </div>
                                        
                                    ) : (
                                        <></>
                                    )}



                                </div>

                            </div>
                        </div>

                    </div>
                </div>

                <div className="col-xl-8">
                    <div className="card">
                        <div className="card-body">
                            <div className="profile-tab">
                                <div className="custom-tab-1">

                                    <div className="tab-content">


                                        

                                        <div id="record" className={`tab-pane fade ${activeToggle === "record" ? "active show" : ""}`}>
                                            <h4 className="text-info mb-4">Toplantı Ses Kaydı</h4>
                                            
                                            {/* Mevcut kayıt bölümü */}
                                            {meetingRecord && meetingRecord.url ? (
                                                <div className="existing-recording p-3 mb-4 bg-white rounded shadow-sm">
                                                    <h6 className="text-info mb-3">
                                                        <i className="fa fa-headphones me-2"></i>
                                                        Mevcut Ses Kaydı
                                                    </h6>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <div className="d-flex align-items-center">
                                                            <i className="fa fa-file-audio-o fa-2x text-info me-3"></i>
                                                            <div className="text-start">
                                                                <p className="mb-0 fw-bold text-truncate" style={{ maxWidth: "250px" }}>
                                                                    {meetingRecord.name}
                                                                </p>
                                                                {meetingRecord.updateDate && (
                                                                    <small className="text-muted">
                                                                        Yüklenme: {new Date(meetingRecord.updateDate.seconds * 1000).toLocaleString('tr-TR')}
                                                                    </small>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <a 
                                                                href={meetingRecord.url} 
                                                                target="_blank" 
                                                                rel="noopener noreferrer"
                                                                className="btn btn-sm btn-outline-info me-2"
                                                            >
                                                                <i className="fa fa-external-link me-1"></i>Aç
                                                            </a>
                                                            <a 
                                                                href={meetingRecord.url} 
                                                                download={meetingRecord.name}
                                                                className="btn btn-sm btn-outline-secondary"
                                                            >
                                                                <i className="fa fa-download me-1"></i>İndir
                                                            </a>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="mt-3">
                                                        <audio 
                                                            controls 
                                                            src={meetingRecord.url} 
                                                            className="audio-player" 
                                                            controlsList="nodownload"
                                                            preload="metadata"
                                                        >
                                                            Tarayıcınız ses elementi desteklemiyor.
                                                        </audio>
                                                    </div>
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                            
                                            <div className="upload-area mb-4">
                                                <form onSubmit={handleRecordSubmit} className="text-center">
                                                    <div 
                                                        className={`upload-container position-relative ${isDragging ? 'dragging' : ''}`}
                                                        onDragOver={handleDragOver}
                                                        onDragLeave={handleDragLeave}
                                                        onDrop={handleDrop}
                                                    >
                                                        {isUploading ? (
                                                            <div className="mb-4">
                                                                <div className="d-flex justify-content-between mb-2">
                                                                    <span className="text-muted">Yükleniyor...</span>
                                                                    <span className="fw-bold">{uploadProgress}%</span>
                                                                </div>
                                                                <div className="progress mb-3" style={{ height: "20px" }}>
                                                                    <div
                                                                        className="progress-bar progress-bar-striped progress-bar-animated bg-info"
                                                                        role="progressbar"
                                                                        style={{ width: `${uploadProgress}%` }}
                                                                        aria-valuenow={uploadProgress}
                                                                        aria-valuemin="0"
                                                                        aria-valuemax="100"
                                                                    >
                                                                        {uploadProgress}%
                                                                    </div>
                                                                </div>
                                                                <div className="alert alert-warning">
                                                                    <i className="fa fa-exclamation-triangle me-2"></i>
                                                                    Lütfen yükleme tamamlanana kadar sayfadan ayrılmayın.
                                                                </div>
                                                                <button 
                                                                    type="button" 
                                                                    className="btn btn-sm btn-outline-danger mt-2"
                                                                    onClick={() => {
                                                                        setIsUploading(false);
                                                                        setUploadProgress(0);
                                                                        window.onbeforeunload = null;
                                                                        toast.error('Yükleme iptal edildi', { id: 'audioUpload' });
                                                                    }}
                                                                >
                                                                    <i className="fa fa-times me-1"></i>
                                                                    İptal Et
                                                                </button>
                                                            </div>
                                                        ) : (
                                                            <>
                                                                {selectedFile ? (
                                                                    <div className="selected-file p-3 bg-white rounded shadow-sm">
                                                                        <div className="d-flex align-items-center justify-content-between">
                                                                            <div className="d-flex align-items-center">
                                                                                <i className="fa fa-file-audio-o fa-2x text-info me-3"></i>
                                                                                <div className="text-start">
                                                                                    <p className="mb-0 fw-bold text-truncate" style={{maxWidth: "250px"}}>{selectedFile.name}</p>
                                                                                    <small className="text-muted">{(selectedFile.size / (1024 * 1024)).toFixed(2)} MB</small>
                                                                                </div>
                                                                            </div>
                                                                            <button 
                                                                                type="button" 
                                                                                className="btn btn-sm btn-outline-danger"
                                                                                onClick={() => setSelectedFile(null)}
                                                                            >
                                                                                <i className="fa fa-times me-1"></i>Kaldır
                                                                            </button>
                                                                        </div>
                                                                        
                                                                        <div className="mt-4 d-flex justify-content-center">
                                                                            <button 
                                                                                type="submit" 
                                                                                className="btn btn-success px-4 py-2"
                                                                                disabled={isUploading}
                                                                            >
                                                                                <i className="fa fa-cloud-upload me-2"></i>
                                                                                Kayıt Yükle
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <>
                                                                        <div className="text-center mb-3">
                                                                            <i className="fa fa-cloud-upload fa-4x text-info mb-3"></i>
                                                                            <h5 className="fw-bold">Ses kaydı yüklemek için buraya sürükleyin</h5>
                                                                            <p className="text-muted">veya</p>
                                                                        </div>
                                                                        <input
                                                                            type="file"
                                                                            id="meeting-audio-upload"
                                                                            className="position-absolute top-0 start-0 w-100 h-100 opacity-0"
                                                                            accept="audio/*,.mp3,.wav,.m4a,.aac,.ogg,.mp4"
                                                                            onChange={handleFileChange}
                                                                            style={{ cursor: 'pointer' }}
                                                                        />
                                                                        <button type="button" className="btn btn-info px-4 py-2">
                                                                            <i className="fa fa-file-audio-o me-2"></i>Dosya Seç
                                                                        </button>
                                                                        <div className="mt-3">
                                                                            <small className="text-muted">Desteklenen formatlar: MP3, WAV, M4A, AAC, OGG, MP4</small>
                                                                            <br />
                                                                            <small className="text-muted">Maksimum dosya boyutu: 100MB</small>
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </form>
                                            </div>
                                        </div>

                                        <div id="transcript" className={`tab-pane fade ${activeToggle === "transcript" ? "active show" : ""}`}>
                                            <h4 className="text-info">Toplantı Dökümü</h4>
                                            <p className="mb-2">
                                                {meeting.meetingTranscripts}
                                            </p>
                                        </div>

                                        <div id="about" className={`tab-pane fade ${activeToggle === "about" ? "active show" : ""}`}>
                                            <h4 className="text-info">{meeting.title}</h4>
                                            <p className="mb-2">
                                                {meeting.meetingDescription}
                                            </p>
                                        </div>

                                      

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </Fragment >

    )
}

export default MeetingDetail