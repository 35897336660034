/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect, useState } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { logout, reset } from '../../../features/auth/authSlice';
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import beetinqlogocolorful from "../../../images/beetinq/beetinqlogocolorful.png";

import beetinqlogo from "../../../images/beetinq/beetinqlogo.png";

// Image
//import profile from "../../../images/profile/pic1.jpg";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth)

  const isAuthenticated = useSelector((state) => state.auth.user !== null);

  const onLogout = () => {
    dispatch(logout())
    //dispatch(reset())
    navigate('/login')
  }



  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
  } = useContext(ThemeContext);
  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);

    //sidebar icon Heart blast
    var handleheartBlast = document.querySelector('.heart');
    function heartBlast() {
      return handleheartBlast.classList.toggle("heart-blast");
    }
    handleheartBlast.addEventListener('click', heartBlast);

  }, []);

  // For scroll
  const [hideOnScroll, setHideOnScroll] = useState(true)
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y
      if (isShow !== hideOnScroll) setHideOnScroll(isShow)
    },
    [hideOnScroll]
  )

  // let scrollPosition = useScrollPosition();
  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  /// Active menu
  let deshBoard = [
    "",
    "dashboard-dark",
    "order-list",
    "event",
    "customer",
    "analytics",
    "reviews",
    "task",

  ],
    app = [
      "app-profile",
      "post-details",
      "app-calender",
      "email-compose",
      "email-inbox",
      "email-read",
      "ecom-product-grid",
      "ecom-product-list",
      "ecom-product-order",
      "ecom-checkout",
      "ecom-invoice",
      "ecom-customers",
      "post-details",
      "ecom-product-detail",
    ],
    email = ["email-compose", "email-inbox", "email-read"],
    shop = [
      "ecom-product-grid",
      "ecom-product-list",
      "ecom-product-list",
      "ecom-product-order",
      "ecom-checkout",
      "ecom-invoice",
      "ecom-customers",
      "ecom-product-detail",
    ],
    charts = [
      "chart-rechart",
      "chart-flot",
      "chart-chartjs",
      "chart-chartist",
      "chart-sparkline",
      "chart-apexchart",
    ],
    bootstrap = [
      "ui-accordion",
      "ui-badge",
      "ui-alert",
      "ui-button",
      "ui-modal",
      "ui-button-group",
      "ui-list-group",
      "ui-media-object",
      "ui-card",
      "ui-carousel",
      "ui-dropdown",
      "ui-popover",
      "ui-progressbar",
      "ui-tab",
      "ui-typography",
      "ui-pagination",
      "ui-grid",
    ],
    plugins = [
      "uc-select2",
      "uc-nestable",
      "uc-sweetalert",
      "uc-toastr",
      "uc-noui-slider",
      "map-jqvmap",
      "uc-lightgallery",
    ],
    redux = [
      "redux-form",
      "redux-wizard",
      "todo",
    ],
    widget = ["widget-basic"],
    forms = [
      "form-element",
      "form-wizard",
      "form-editor-summernote",
      "form-pickers",
      "form-validation-jquery",
    ],
    table = ["table-bootstrap-basic", "table-datatable-basic"],
    pages = [
      "page-register",
      "page-login",
      "page-lock-screen",
      "page-error-400",
      "page-error-403",
      "page-error-404",
      "page-error-500",
      "page-error-503",
      "empty",
    ],
    error = [
      "page-error-400",
      "page-error-403",
      "page-error-404",
      "page-error-500",
      "page-error-503",
    ];
  return (
    <div
      className={`deznav ${iconHover} ${sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
        ? hideOnScroll > 120
          ? "fixed"
          : ""
        : ""
        }`}
    >
      <PerfectScrollbar className="deznav-scroll">
        <MM className="metismenu" id="menu">

          <li>
            <Link className="has-arrow ai-icon" to="/MeetingAdd" >
              <i className="fa fa-plus text-info"></i>
              <span className="nav-text">Toplantı Planla</span>
            </Link>
          </li>
          
          <li>
            <Link className="has-arrow ai-icon" to="/dashboard" >
              <i className="fa fa-th-large text-info"></i>
              <span className="nav-text">Anasayfa</span>
            </Link>
          </li>

        

          <li>
            <Link className="has-arrow ai-icon" to="/Discover" >
              <i className="fa fa-compass text-info"></i>
              <span className="nav-text">Keşfet</span>
            </Link>
          </li>
  

          <li>
            <Link className="has-arrow ai-icon" to="/past" >
              <i className="fa fa-rotate-left text-info"></i>
              <span className="nav-text">Geçmiş</span>
            </Link>
          </li>
          

          

          <li>
            <Link className="has-arrow ai-icon" to="/MyProfile" >
              <i className="fa fa-user text-info"></i>
              <span className="nav-text">Hesabım</span>
            </Link>
          </li>

          <li>
            <Link className="has-arrow ai-icon" onClick={onLogout} >
              <i className="fa fa-power-off text-info"></i>
              <span className="nav-text">Çıkış</span>
            </Link>
          </li>

          <li>
            <Link className="has-arrow ai-icon" to="https://apps.apple.com/us/app/beetinq/id1608291189" >
              <i className="fa fa-apple text-info" aria-hidden="true"></i>
              <span className="nav-text">AppStore</span>
            </Link>
          </li>

          <li>
            <Link className="has-arrow ai-icon" to="https://play.google.com/store/apps/details?id=com.beetinq" >
              <i className="fa fa-android text-info" aria-hidden="true"></i>
              <span className="nav-text">PlayStore</span>
            </Link>
          </li>



          {/* 
          <li className={`${pages.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="/meetings" >
              <i className="flaticon-022-copy"></i>
              <span className="nav-text">Toplantılarım</span>
            </Link>
            <ul>
              <li><Link to="/meetings">Toplantılarım</Link></li>
            </ul>
          </li>
          <li className={`${bootstrap.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#" >
              <i className="flaticon-086-star"></i>
              <span className="nav-text">Bağlantılarım</span>
            </Link>
            <ul>
              <li><Link className={`${path === "todo" ? "mm-active" : ""}`} to="/dashboard">Bağlantılarım</Link></li>
            </ul>
          </li>
          <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
            <Link to="widget-basic" className="ai-icon" >
              <i className="flaticon-013-checkmark"></i>
              <span className="nav-text">Öneriler</span>
            </Link>
            <ul>
              <li><Link className={`${path === "todo" ? "mm-active" : ""}`} to="/dashboard">Öneriler</Link></li>
            </ul>
          </li>
          <li className={`${plugins.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#" >
              <i className="flaticon-045-heart"></i><span className="nav-text">Plugins</span>
            </Link>
          </li>
          <li className={`${redux.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#" >
              <i className="flaticon-087-stop"></i><span className="nav-text">Redux</span>
            </Link>
            <ul>
              <li><Link className={`${path === "todo" ? "mm-active" : ""}`} to="/dashboard">Dashboard</Link></li>
            </ul>
          </li>
          <li className={`${forms.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#" >
              <i className="flaticon-085-signal"></i>
              <span className="nav-text forms">Forms</span>
            </Link>
            <ul>
              <li><Link className={`${path === "todo" ? "mm-active" : ""}`} to="/dashboard">Dashboard</Link></li>
            </ul>
          </li>
          <li className={`${table.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="/EventList" ><i className="flaticon-043-menu"></i><span className="nav-text">Etkinlik List Video</span></Link>
            <ul>
              <li><Link className={`${path === "todo" ? "mm-active" : ""}`} to="/EventList">Etkinlik List Video</Link></li>
            </ul>
          </li>
          */}



        </MM>




        <div className="copyright altsabit">
          <br /><br /><br />
          <p className="fs-12">
            <img src={beetinqlogocolorful} className="img-lonca-logo30" />
            <span className="heart"></span>
            <img src={beetinqlogo} className="img-lonca-logo30" />
          </p>
        </div>

      </PerfectScrollbar>
    </div >
  );
};

export default SideBar;
