import { db } from "../../config/firebase";
import { collection, addDoc, getDoc, query, where, limit, getDocs, doc, deleteDoc, updateDoc, orderBy, arrayUnion, Timestamp } from 'firebase/firestore'
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import { storage } from "../../config/firebase";
import axios from 'axios';


const createMeeting = async (meetingData, userId) => {
    const colRef = await collection(db, 'meetings');
    const docRef = await addDoc(colRef, {
        ...meetingData,
        activityOrder: 0,
        activityType: "standard",
        adminUsers: [userId],
        amount: 0.01,
        blackList: [],
        btobmeetings: [],
        businessId: "",
        category: "0",
        changingUsers: [],
        coverPhoto: "",
        createDate: Timestamp.fromDate(new Date()),
        createMeetingUserId: userId,
        //endDate: Timestamp.fromDate(new Date()),
        explore: false,
        files: [],
        follower: [],
        image: "",
        invitations: [],
        isFree: true,
        isFront: false,
        isMatching: false,
        isactivity: false,
        istob: false,
        isbtobsession: false,
        issubactivity: false,
        issubmeeting: false,
        location: "",
        matchingSurveyUsers: [],
        meetingBusinessState: false,
        //meetingDescription: "",
        meetingTemplate: "99",
        meetingType: "standard",
        moderatorUsers: [],
        modules: [],
        nonUserInvited: [],
        premium: true,
        private: true,
        sessionbreakduaration: 0,
        sessioncount: 0,
        sessiondeskcount: 0,
        sessionduration: 0,
        stableUsers: [],
        //startDate: Timestamp.fromDate(new Date()),
        state: true,
        status: "0",
        subactivitys: [],
        submeetings: [],
        tags: [],
        //title:"",
        topmeetingid: "",
        //type: "0",
        url: "",
        urlAttendee: "",
        urlHost: "",
        urlLocation: "",
        urlWeb: "",
        userLimit: 0,
        userReq: [],
        userId: userId,
        users: [userId],
    })
    const docSnap = await getDoc(docRef);
    console.log("docSnap.id: " + docSnap.id);

    const docRefU = await doc(db, 'meetings', docSnap.id);
    await updateDoc(docRefU, { meetingId: docSnap.id })

    return { ...docSnap.data(), meetingId: docSnap.id };
}

const getMeetings = async () => {
    const userId = JSON.parse(localStorage.getItem('user')).uid;
    const colRef = await collection(db, 'meetings');
    const q = query(
        colRef,
        where("users", "array-contains", userId),
        //where("isactivity", "==", false),
        orderBy('startDate', 'desc'),
    );
    const querySnapshot = await getDocs(q);
    let dizi = [];
    querySnapshot.forEach((doc) => {
        dizi.push({ ...doc.data(), id: doc.id })
    });
    return dizi;
}

const getFutureMeetings = async () => {
    const userId = JSON.parse(localStorage.getItem('user')).uid;
    const colRef = await collection(db, 'meetings');
    
    // Şu anki zamanı Firestore Timestamp olarak al
    const currentDate = Timestamp.fromDate(new Date());
    
    const q = query(
        colRef,
        where("users", "array-contains", userId),
        // Sadece startDate şu anki tarihten büyük veya eşit olanları getir
        where("startDate", ">=", currentDate),
        // Gelecekteki toplantıları yakın tarihli olanlar önce gelecek şekilde sırala
        orderBy('startDate', 'asc'),
    );
    
    const querySnapshot = await getDocs(q);
    let dizi = [];
    querySnapshot.forEach((doc) => {
        dizi.push({ ...doc.data(), id: doc.id })
    });
    return dizi;
}

const getPastMeetings = async () => {
    const userId = JSON.parse(localStorage.getItem('user')).uid;
    const colRef = await collection(db, 'meetings');
    
    // Şu anki zamanı Firestore Timestamp olarak al
    const currentDate = Timestamp.fromDate(new Date());
    
    const q = query(
        colRef,
        where("users", "array-contains", userId),
        // Sadece startDate şu anki tarihten büyük veya eşit olanları getir
        where("startDate", "<=", currentDate),
        // Gelecekteki toplantıları yakın tarihli olanlar önce gelecek şekilde sırala
        orderBy('startDate', 'desc'),
    );
    
    const querySnapshot = await getDocs(q);
    let dizi = [];
    querySnapshot.forEach((doc) => {
        dizi.push({ ...doc.data(), id: doc.id })
    });
    return dizi;
}

const getMeetingsFront = async () => {
    const colRef = await collection(db, 'meetings');
    const q = query(
        colRef,
        where("isactivity", "==", true),
        where("explore", "==", true),
        where("isFront", "==", true),
        where("state", "==", true),
        //where("businessId", "==", "j6HB1d7Ua41NTFhvH4lw"),
        where('endDate', '>=', new Date()),
        orderBy('endDate'),
        //where('endDate', '>=', new Date())
        //where("startDate", ">=", new Date()),
        //limit(3) // İlk 3 belgeyi alacak şekilde sınırlandırma
    );
    const querySnapshot = await getDocs(q);
    let dizi = [];
    querySnapshot.forEach((doc) => {
        dizi.push({ ...doc.data(), id: doc.id })
    });

    // JavaScript ile activityOrder'a göre sıralama
    dizi.sort((a, b) => a.activityOrder - b.activityOrder);

    // İlk 6 elemanı almak için slice kullanıyoruz
    const firstItems = dizi.slice(0, 3);



    return firstItems;
}

const getMeetingsPast = async () => {
    const colRef = await collection(db, 'meetings');
    const q = query(
        colRef,
        where("isactivity", "==", true),
        where("explore", "==", true),
        //where("businessId", "==", "j6HB1d7Ua41NTFhvH4lw"),
        where('endDate', '<=', new Date()),
        where("state", "==", true),
        orderBy('endDate', 'desc'),
        //where('endDate', '>=', new Date())
        //where("startDate", ">=", new Date()),
    );
    const querySnapshot = await getDocs(q);
    let dizi = [];
    querySnapshot.forEach((doc) => {
        dizi.push({ ...doc.data(), id: doc.id })
    });
    return dizi;
}

const getSubMeetings = async (meetingId) => {
    const colRef = await collection(db, 'meetings');
    const q = query(
        colRef,
        where("topmeetingid", "==", meetingId),
        where("issubmeeting", "==", true),
        where("isbtob", "==", false),
        where("state", "==", true),
        orderBy('startDate'),
    );
    const querySnapshot = await getDocs(q);
    let dizi = [];
    querySnapshot.forEach((doc) => {
        dizi.push({ ...doc.data(), id: doc.id })
    });
    return dizi;
}

const getMeeting = async (meetingId) => {

    const colRef = await collection(db, 'meetings');
    const q = query(
        colRef,
        where("meetingId", "==", meetingId),
        //where("isactivity", "==", true),
        //where("private", "==", false),
        where("state", "==", true),
        limit(1)
    );
    const querySnapshot = await getDocs(q);
    let dizi = [];
    querySnapshot.forEach((doc) => {
        dizi.push({ ...doc.data(), id: doc.id })
    });
    return dizi[0];

}


/*
const getMeeting = async (meetingId) => {
    const docRef = await doc(db, 'meetings', meetingId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        return { ...docSnap.data(), id: docSnap.id }
    } else {
        return null;
    }
}
*/

const deleteMeeting = async (id) => {
    const docRef = await doc(db, 'meetings', id);
    await deleteDoc(docRef);
    return id
}

const updateMeeting = async (meetingData, id) => {
    const docRef = await doc(db, 'meetings', id);
    await updateDoc(docRef, meetingData)
    const docSnap = await getDoc(docRef)
    return { ...docSnap.data(), id: docSnap.id };
}

const updateMeetingRecord = async (meetingData, id, onProgress) => {
    try {
        // Önce dosyayı storage'a yükle
        const file = meetingData.recordFile;
        
        // Dosya adını benzersiz hale getir
        const fileExtension = file.name.split('.').pop().toLowerCase();
        const timestamp = new Date().getTime();
        
        // Basit bir UUID benzeri oluştur (tam bir UUID için ek bir kütüphane gerekli olabilir)
        const randomPart = Math.random().toString(36).substring(2, 10);
        
        // Benzersiz dosya adı oluştur - toplantı ID + zaman damgası + rastgele sayı + uzantı
        const uniqueFileName = `audio_${id}_${timestamp}_${randomPart}.${fileExtension}`;
        
        // Orijinal dosya adını sakla
        const originalFileName = file.name;
        
        // Benzersiz dosya adıyla storage referansı oluştur
        const storageRef = ref(storage, `meetings/${id}/record/${uniqueFileName}`);
        
        // Set up upload task with progress monitoring
        const uploadTask = uploadBytesResumable(storageRef, file);
        
        // Return a promise that resolves when the upload is complete
        return new Promise((resolve, reject) => {
            // Monitor upload progress
            uploadTask.on(
                'state_changed',
                (snapshot) => {
                    // Calculate and report progress
                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    
                    // If onProgress callback is provided, call it with the progress
                    if (typeof onProgress === 'function') {
                        onProgress(progress);
                    }
                },
                (error) => {
                    // Handle upload errors
                    console.error('Upload error:', error);
                    reject(error);
                },
                async () => {
                    try {
                        // Get the download URL
                        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                        
                        // Update the Firestore document
                        const docRef = doc(db, 'meetings', id);
                        const updateData = {
                            recordingUrl: downloadURL,
                            meetingrecordurl: downloadURL,
                            recordingName: originalFileName, // Kullanıcıya gösterilecek orijinal dosya adı
                            recordingFileName: uniqueFileName, // Firebase'de saklanan benzersiz dosya adı
                            recordingUpdateDate: Timestamp.fromDate(new Date())
                        };
                        
                        await updateDoc(docRef, updateData);
                        const docSnap = await getDoc(docRef);
                        
                        // Resolve the promise with the updated data
                        resolve({ 
                            ...docSnap.data(), 
                            id: docSnap.id,
                            uploadProgress: 100 
                        });
                    } catch (error) {
                        reject(error);
                    }
                }
            );
        });
    } catch (error) {
        throw new Error(error.message);
    }
}

const getMeetingUsers = async (meetingId) => {
    const colRef = await collection(db, 'users');
    const q = query(
        colRef,
        where('meetings', 'array-contains-any', [meetingId]),
        orderBy('name'),
    );
    const querySnapshot = await getDocs(q);
    let dizi = [];
    querySnapshot.forEach((doc) => {
        dizi.push({ ...doc.data(), id: doc.id })
    });
    return dizi;
}

const getMeetingAgendas = async (meetingId) => {
    const userId = JSON.parse(localStorage.getItem('user')).uid;
    const colRef = await collection(db, 'meetings', meetingId, 'agenda');
    const q = query(
        colRef,
        where('status', "==", true),
        orderBy('startTime', 'asc'),
    );
    const querySnapshot = await getDocs(q);
    let dizi = [];
    querySnapshot.forEach((doc) => {
        dizi.push({ ...doc.data(), id: doc.id })
    });
    return dizi;
}

const getMeetingNotes = async (meetingId) => {
    const userId = JSON.parse(localStorage.getItem('user')).uid;
    const colRef = await collection(db, 'meetings', meetingId, 'notes');
    const q = query(
        colRef,
        where('users', 'array-contains-any', [userId]),
        orderBy('messageDate', 'desc'),
    );
    const querySnapshot = await getDocs(q);
    let dizi = [];
    querySnapshot.forEach((doc) => {
        dizi.push({ ...doc.data(), id: doc.id })
    });
    return dizi;
}

const createMeetingNote = async (noteData, meetingId, userId) => {
    const colRef = await collection(db, 'meetings', meetingId, 'notes');
    const docRef = await addDoc(colRef, {
        ...noteData,
        messageDate: Timestamp.fromDate(new Date()),
        userId: userId,
        users: [userId],
    })
    const docSnap = await getDoc(docRef);
    console.log("docSnap.id: " + docSnap.id);


    const docRefU = await doc(db, 'meetings', meetingId, 'notes', docSnap.id);
    await updateDoc(docRefU, { noteId: docSnap.id })

    return { ...docSnap.data(), noteId: docSnap.id };
}


const joinMeeting = async (meetingId, userId) => {
    const docRefUser = await doc(db, 'users', userId);

    const docRefMeeting = await doc(db, 'meetings', meetingId);


    try {

        await updateDoc(docRefMeeting, {
            users: arrayUnion(userId)
        });

        // Diziye yeni elemanı ekleyin
        await updateDoc(docRefUser, {
            meetings: arrayUnion(meetingId)
        });



        console.log('Diziye yeni eleman başarıyla eklendi.');
    } catch (error) {
        console.error('Diziye eleman eklerken hata oluştu:', error);
    }

    return { status: "true" };
}


const getMeetingAnnouncements = async (meetingId) => {
    const userId = JSON.parse(localStorage.getItem('user')).uid;
    const colRef = await collection(db, 'meetings', meetingId, 'announcement');
    const q = query(
        colRef,
        where('status', "==", true),
        orderBy('messageDate', 'desc'),
    );
    const querySnapshot = await getDocs(q);
    let dizi = [];
    querySnapshot.forEach((doc) => {
        dizi.push({ ...doc.data(), id: doc.id })
    });
    return dizi;
}




const meetingService = {
    createMeeting,
    getMeetings,
    deleteMeeting,
    updateMeeting,
    getMeeting,
    getSubMeetings,
    getMeetingUsers,
    getMeetingNotes,
    createMeetingNote,
    joinMeeting,
    getMeetingsPast,
    getMeetingsFront,
    getFutureMeetings,
    getPastMeetings,
    getMeetingAgendas,
    getMeetingAnnouncements,
    updateMeetingRecord,
}

export default meetingService