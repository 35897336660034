import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import meetingService from './meetingService'

const initialState = {
    meetings: [],
    meetingsPast: [],
    meetingsFront: [],
    meetingUsers: [],
    meetingAnnouncements: [],
    meeting: [],
    meetingNotes: [],
    meetingAgendas: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
}

// Reducer'da veriyi normalize eden bir yardımcı fonksiyon
const normalizeFirebaseData = (data) => {
    if (!data) return data;
    
    // Eğer data bir array ise
    if (Array.isArray(data)) {
        return data.map(item => normalizeFirebaseData(item));
    }
    
    // Eğer data bir object ise
    if (typeof data === 'object') {
        const normalized = {};
        
        for (const [key, value] of Object.entries(data)) {
            // Timestamp objelerini Unix timestamp'e çevir
            if (value && typeof value.toDate === 'function') {
                normalized[key] = value.toDate().getTime();
            }
            // İç içe objeleri/arrayleri recursive olarak normalize et
            else if (value && typeof value === 'object') {
                normalized[key] = normalizeFirebaseData(value);
            }
            // Diğer değerleri olduğu gibi kopyala
            else {
                normalized[key] = value;
            }
        }
        
        return normalized;
    }
    
    return data;
};

export const meetingSlice = createSlice({
    name: 'meeting',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.message = ''
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(createMeeting.pending, (state) => {
                state.isLoading = true
            })
            .addCase(createMeeting.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.meetings.push(action.payload)
            })
            .addCase(createMeeting.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(getMeetings.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getMeetings.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.meetings = normalizeFirebaseData(action.payload)
                state.isError = false
            })
            .addCase(getMeetings.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.message = action.payload
                state.isError = true
            })


            .addCase(getFutureMeetings.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getFutureMeetings.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.meetings = normalizeFirebaseData(action.payload)
                state.isError = false
            })
            .addCase(getFutureMeetings.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.message = action.payload
                state.isError = true
            })

            .addCase(getPastMeetings.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getPastMeetings.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.meetings = normalizeFirebaseData(action.payload)
                state.isError = false
            })
            .addCase(getPastMeetings.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.message = action.payload
                state.isError = true
            })

            .addCase(getSubMeetings.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getSubMeetings.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.meetings = action.payload
                state.isError = false
            })
            .addCase(getSubMeetings.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.message = action.payload
                state.isError = true
            })

            .addCase(deleteMeeting.pending, (state) => {
                state.isLoading = true
            })
            .addCase(deleteMeeting.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.meetings = state.meetings.filter((meeting) => meeting.id !== action.payload)
                state.isError = false
            })
            .addCase(deleteMeeting.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.message = action.payload
                state.isError = true
            })

            .addCase(updateMeeting.pending, (state) => {
                state.isLoading = true
            })
            .addCase(updateMeeting.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.meetings.unshift(action.payload)
            })
            .addCase(updateMeeting.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.message = action.payload
                state.isError = true
            })

            .addCase(getMeeting.pending, (state) => {
                state.isLoading = true
                state.isSuccess = false
            })
            .addCase(getMeeting.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.meeting = normalizeFirebaseData(action.payload)
            })
            .addCase(getMeeting.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.message = action.payload
                state.isError = true
            })

            .addCase(getMeetingUsers.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getMeetingUsers.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.meetingUsers = normalizeFirebaseData(action.payload)
                state.isError = false
            })
            .addCase(getMeetingUsers.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.message = action.payload
                state.isError = true
            })

            .addCase(getMeetingAgendas.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getMeetingAgendas.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.meetingAgendas = normalizeFirebaseData(action.payload)
                state.isError = false
            })
            .addCase(getMeetingAgendas.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(getMeetingNotes.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getMeetingNotes.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.meetingNotes = normalizeFirebaseData(action.payload)
                state.isError = false
            })
            .addCase(getMeetingNotes.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.message = action.payload
                state.isError = true
            })

            .addCase(createMeetingNote.pending, (state) => {
                state.isLoading = true
            })
            .addCase(createMeetingNote.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.meetingNotes.unshift(action.payload)
            })
            .addCase(createMeetingNote.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.message = action.payload
                state.isError = true
            })

            .addCase(joinMeeting.pending, (state) => {
                state.isLoading = true
            })
            .addCase(joinMeeting.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.meetingNotes.unshift(action.payload)
            })
            .addCase(joinMeeting.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.message = action.payload
                state.isError = true
            })

            .addCase(getMeetingsFront.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getMeetingsFront.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.meetingsFront = action.payload
                state.isError = false
            })
            .addCase(getMeetingsFront.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.message = action.payload
                state.isError = true
            })

            .addCase(getMeetingsPast.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getMeetingsPast.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.meetingsPast = action.payload
                state.isError = false
            })
            .addCase(getMeetingsPast.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.message = action.payload
                state.isError = true
            })

            .addCase(getMeetingAnnouncements.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getMeetingAnnouncements.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.meetingAnnouncements = normalizeFirebaseData(action.payload)
                state.isError = false
            })
            .addCase(getMeetingAnnouncements.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.message = action.payload
                state.isError = true
            })

            .addCase(updateMeetingRecord.pending, (state) => {
                state.isLoading = true
            })
            .addCase(updateMeetingRecord.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.meeting = action.payload
            })
            .addCase(updateMeetingRecord.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
    }
})

/*
export const createMeeting = createAsyncThunk(
    'meetings/create',
    async (meetingData, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token;
            return await meetingService.createMeeting(meetingData, token);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) 
                || error.message 
                || error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
*/

export const createMeeting = createAsyncThunk('meeting/createMeeting', async (meetingData, thunkAPI) => {
    try {
        const userId = thunkAPI.getState().auth.user.uid
        return await meetingService.createMeeting(meetingData, userId)
    } catch (error) {
        const message = error.message;
        console.log("createMeetingError: " + message);
        return thunkAPI.rejectWithValue(message);
    }
})


export const getMeetings = createAsyncThunk('meeting/getMeetings', async (_, thunkAPI) => {
    try {
        return await meetingService.getMeetings()
    } catch (error) {
        const message = error.message
        return thunkAPI.rejectWithValue(message)
    }
})

export const getFutureMeetings = createAsyncThunk('meeting/getFutureMeetings', async (_, thunkAPI) => {
    try {
        return await meetingService.getFutureMeetings()
    } catch (error) {
        const message = error.message
        return thunkAPI.rejectWithValue(message)
    }
})

export const getPastMeetings = createAsyncThunk('meeting/getPastMeetings', async (_, thunkAPI) => {
    try {
        return await meetingService.getPastMeetings()
    } catch (error) {
        const message = error.message
        return thunkAPI.rejectWithValue(message)
    }
})

export const getMeetingsFront = createAsyncThunk('meeting/getMeetingsFront', async (_, thunkAPI) => {
    try {
        return await meetingService.getMeetingsFront()
    } catch (error) {
        const message = error.message
        return thunkAPI.rejectWithValue(message)
    }
})

export const getMeetingsPast = createAsyncThunk('meeting/getMeetingsPast', async (_, thunkAPI) => {
    try {
        return await meetingService.getMeetingsPast()
    } catch (error) {
        const message = error.message
        return thunkAPI.rejectWithValue(message)
    }
})

export const getSubMeetings = createAsyncThunk('meeting/getSubMeetings', async (meetingId, thunkAPI) => {
    try {
        return await meetingService.getSubMeetings(meetingId)
    } catch (error) {
        const message = error.message
        return thunkAPI.rejectWithValue(message)
    }
})

export const getMeeting = createAsyncThunk('meeting/getMeeting', async (id, thunkAPI) => {
    try {
        return await meetingService.getMeeting(id)
    } catch (error) {
        const message = error.message
        return thunkAPI.rejectWithValue(message)
    }
})

export const deleteMeeting = createAsyncThunk('meeting/deleteMeeting', async (id, thunkAPI) => {
    try {
        return await meetingService.deleteMeeting(id)
    } catch (error) {
        const message = error.message
        return thunkAPI.rejectWithValue(message)
    }
})

export const updateMeeting = createAsyncThunk('meeting/updateMeeting', async (meetingData, id, thunkAPI) => {
    try {
        return await meetingService.updateMeeting(meetingData, id)
    } catch (error) {
        const message = error.message
        return thunkAPI.rejectWithValue(message)
    }
})

export const updateMeetingRecord = createAsyncThunk(
    'meetings/updateRecord',
    async ({ meetingData, id, onProgress }, thunkAPI) => {
        try {
            return await meetingService.updateMeetingRecord(meetingData, id, onProgress)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const getMeetingAgendas = createAsyncThunk('meeting/getMeetingAgendas', async (meetingId, thunkAPI) => {
    try {
        return await meetingService.getMeetingAgendas(meetingId)
    } catch (error) {
        const message = error.message
        return thunkAPI.rejectWithValue(message)
    }
})

export const getMeetingAnnouncements = createAsyncThunk('meeting/getMeetingAnnouncements', async (meetingId, thunkAPI) => {
    try {
        return await meetingService.getMeetingAnnouncements(meetingId)
    } catch (error) {
        const message = error.message
        return thunkAPI.rejectWithValue(message)
    }
})

export const getMeetingNotes = createAsyncThunk('meeting/getMeetingNotes', async (meetingId, thunkAPI) => {
    try {
        return await meetingService.getMeetingNotes(meetingId)
    } catch (error) {
        const message = error.message
        return thunkAPI.rejectWithValue(message)
    }
})

export const createMeetingNote = createAsyncThunk('meeting/createMeetingNote', async (noteData, thunkAPI) => {
    try {
        const userId = thunkAPI.getState().auth.user.uid
        return await meetingService.createMeetingNote(noteData, noteData.meetingId, userId)
    } catch (error) {
        const message = error.message;
        console.log("createMeetingNoteError: " + message);
        return thunkAPI.rejectWithValue(message);
    }
})

export const joinMeeting = createAsyncThunk('meeting/etkinligekatil', async (meetingId, thunkAPI) => {
    try {
        const userId = thunkAPI.getState().auth.user.uid
        return await meetingService.joinMeeting(meetingId, userId)
    } catch (error) {
        const message = error.message
        return thunkAPI.rejectWithValue(message)
    }
})

export const getMeetingUsers = createAsyncThunk('meeting/getMeetingUsers', async (meetingId, thunkAPI) => {
    try {
        return await meetingService.getMeetingUsers(meetingId)
    } catch (error) {
        const message = error.message
        return thunkAPI.rejectWithValue(message)
    }
})

export default meetingSlice.reducer
