import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import profileReducer from '../features/profile/profileSlice';
import eventReducer from '../features/event/eventSlice';
import userReducer from '../features/user/userSlice';
import meetingReducer from '../features/meeting/meetingSlice';


export const store = configureStore({
  reducer: {
    auth: authReducer,
    event: eventReducer,
    meeting: meetingReducer,
    user: userReducer,
    profile: profileReducer,

  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: true
  })
});