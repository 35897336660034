import React, { useState, useEffect } from "react";
import { useNavigate, Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { format } from 'date-fns';

import Spinner from '../../components/Spinner/Spinner'
import { getFutureMeetings, reset } from '../../features/meeting/meetingSlice'



function MeetingFutureList() {



    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.auth);
    const isAuthenticated = useSelector((state) => state.auth.user !== null);
    const { meetings, isLoading, isError, message } = useSelector((state) => state.meeting);


    useEffect(() => {

        if (!isAuthenticated) {
            navigate('/login')
        }

        if (isError) {
            console.log(message);
        }


        dispatch(getFutureMeetings())

        /*
        return () => {
            if (isAuthenticated) {
                dispatch(reset())
            }
        }
        */

    }, [isAuthenticated, navigate, isError, message, dispatch])


    if (isLoading) {
        return <Spinner />
    }

    const formatDate = (date) => {
        if (!date) return '';
        // Handle both Firestore Timestamp and regular Date objects
        const dateObj = date.toDate ? date.toDate() : new Date(date);
        return format(dateObj, "dd MMM yyyy HH:mm");
    };







    return (
        <>
            <div className="row">

                {meetings.length > 0 ? (
                    <>
                        {meetings.map((meeting, index) => (
                            <div className="col-xl-6 col-xxl-6 col-lg-6 col-md-6 col-sm-6" key={index}>
                                <Link to={"/MeetingDetail/" + meeting.meetingId}>
                                    <div className="card project-boxed">
                                       
                                        <div className="card-header align-items-start">
                                            <div>
                                                <h6 className="fs-18 font-w500 mb-3 text-black user-name">{meeting.title}</h6>
                                                <div className="text-dark fs-14 text-nowrap"><i className="fa fa-calendar me-3" aria-hidden="true"></i>{formatDate(meeting.startDate)}</div>
                                                <div className="text-dark fs-14 text-nowrap"><i className="fa fa-briefcase me-3" aria-hidden="true"></i>{meeting.isactivity? "Etkinlik" : "Toplantı"}</div>
                                                <div className="text-dark fs-14 text-nowrap"><i className="fa fa-star me-3" aria-hidden="true"></i>{meeting.type == 0 ? "Fiziksel" : meeting.type == 1 ? "Online" : meeting.type == 2 ? "Hibrit" : "Diğer"}</div>
                                                <div className="text-dark fs-14 text-nowrap"><i className="fa fa-user me-3" aria-hidden="true"></i>{meeting.users?.length} Katılımcı</div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </>
                ) : (
                    <h3>Etkinlik Bulunamadı</h3>
                )}
            </div >
        </>

    )
}

export default MeetingFutureList