import React, { useState, useEffect } from 'react';
// Try with explicit imports from specific modules
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useDispatch, useSelector } from 'react-redux';
import { createMeeting, meetingSlice } from '../../features/meeting/meetingSlice';
import { useNavigate } from 'react-router-dom';
import { Timestamp } from 'firebase/firestore';

function SimpleMeetingAdd() {
    const [formData, setFormData] = useState({
        title: '',
        date: '',
        startTime: '',
        endTime: '',
        briefing: '',
        meetingType: '',
        physicalLocation: '',
        physicalMapLink: '',
        onlineMeetingLink: ''
    });
    
    // Form gönderildiğini takip etmek için state ekle
    const [formSubmitted, setFormSubmitted] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const { isLoading, isError, isSuccess, message } = useSelector(
        (state) => state.meeting
    );
    
    useEffect(() => {
        if (isError) {
            console.error(message);
            // Hata durumunda formSubmitted değerini sıfırla
            setFormSubmitted(false);
        }
        
        // Sadece form gönderildiyse ve işlem başarılıysa yönlendirme yap
        if (formSubmitted && isSuccess) {
            dispatch(meetingSlice.actions.reset());
            navigate('/dashboard');
            // Formu sıfırla
            setFormSubmitted(false);
        }
        
        return () => {
            dispatch(meetingSlice.actions.reset());
        };
    }, [isSuccess, isError, message, navigate, dispatch, formSubmitted]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        
        // Tarih ve saat bilgilerini birleştirerek Date objeleri oluştur
        const startDateTime = new Date(formData.date + 'T' + formData.startTime);
        const endDateTime = new Date(formData.date + 'T' + formData.endTime);
        
        const meetingData = {
            title: formData.title,
            meetingDescription: formData.briefing,
            type: formData.meetingType,
            location: formData.physicalLocation || '',
            urlLocation: formData.physicalMapLink || '',
            urlAttendee: formData.onlineMeetingLink || '',
            urlHost: formData.onlineMeetingLink || '',
            url: formData.onlineMeetingLink || '',

            // Firebase Timestamp objesi oluştur
            startDate: Timestamp.fromDate(startDateTime),
            endDate: Timestamp.fromDate(endDateTime),
            createDate: Timestamp.fromDate(new Date())
        };
        
        // Form gönderildiğini belirt
        setFormSubmitted(true);
        dispatch(createMeeting(meetingData));
    };

    // Determine which additional fields to show based on meeting type
    const showPhysicalFields = formData.meetingType === '0' || formData.meetingType === '2';
    const showOnlineFields = formData.meetingType === '1' || formData.meetingType === '2';

    return (
        <Container>
            <Card>
                <Card.Header>
                    <h4>Toplantı oluştur</h4>
                </Card.Header>
                <Card.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label>Toplantı Başlığı</Form.Label>
                            <Form.Control 
                                type="text" 
                                name="title"
                                value={formData.title}
                                onChange={handleChange}
                                placeholder="Başlık girin" 
                                required
                            />
                        </Form.Group>

                        <Row>
                            <Col md={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Tarih</Form.Label>
                                    <Form.Control 
                                        type="date" 
                                        name="date"
                                        value={formData.date}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            
                            <Col md={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Başlangıç Saati</Form.Label>
                                    <Form.Control 
                                        type="time" 
                                        name="startTime"
                                        value={formData.startTime}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            
                            <Col md={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Bitiş Saati</Form.Label>
                                    <Form.Control 
                                        type="time" 
                                        name="endTime"
                                        value={formData.endTime}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Form.Group className="mb-3">
                            <Form.Label>Toplantı Hakkında Brifing</Form.Label>
                            <Form.Control 
                                as="textarea" 
                                rows={3}
                                name="briefing"
                                value={formData.briefing}
                                onChange={handleChange}
                                placeholder="Toplantının amacı, gündemi veya diğer bilgileri..." 
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Toplantı Tipi</Form.Label>
                            <Form.Select
                                name="meetingType"
                                value={formData.meetingType}
                                onChange={handleChange}
                                required
                            >
                                <option value="0">Fiziksel</option>
                                <option value="1">Online</option>
                                <option value="2">Hibrit</option>
                                <option value="3">Telefon</option>
                                <option value="4">Yazışma</option>
                                <option value="99">Diğer</option>
                            </Form.Select>
                        </Form.Group>

                        {/* Conditional fields based on meeting type */}
                        {showPhysicalFields && (
                            <>
                                <Form.Group className="mb-3">
                                    <Form.Label>Fiziksel Toplantı Yeri</Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        name="physicalLocation"
                                        value={formData.physicalLocation}
                                        onChange={handleChange}
                                        placeholder="Toplantı adresini girin" 
                                        required={formData.meetingType === 'Fiziksel' || formData.meetingType === 'Hibrit'}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Fiziksel Toplantı Harita Konumu</Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        name="physicalMapLink"
                                        value={formData.physicalMapLink}
                                        onChange={handleChange}
                                        placeholder="Harita bağlantısını girin (Google Maps vb.)" 
                                    />
                                </Form.Group>
                            </>
                        )}

                        {showOnlineFields && (
                            <Form.Group className="mb-3">
                                <Form.Label>Online Toplantı Bağlantısı</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    name="onlineMeetingLink"
                                    value={formData.onlineMeetingLink}
                                    onChange={handleChange}
                                    placeholder="Zoom, Teams veya diğer toplantı bağlantısını girin" 
                                    required={formData.meetingType === 'Online' || formData.meetingType === 'Hibrit'}
                                />
                            </Form.Group>
                        )}

                        <Button 
                            variant="primary" 
                            type="submit" 
                            className="mt-3"
                            disabled={isLoading}
                        >
                            {isLoading ? 'Kaydediliyor...' : 'Kaydet'}
                        </Button>
                    </Form>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default SimpleMeetingAdd; 